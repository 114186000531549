RedactorX.lang['fr'] = {
  accessibility: {
    'help-label': 'Rich text editor',
  },
  placeholders: {
    figcaption: 'Type caption (optional)',
  },
  popup: {
    back: 'Retour',
    link: 'Lien',
    add: 'Ajouter',
    image: 'Image',
    'add-image': 'Ajouter Image',
  },
  shortcuts: {
    'meta-a': 'Sélectionner tout',
    'meta-z': 'Annuler',
    'meta-shift-z': 'Refaire',
    'meta-shift-m': 'Supprimer le format',
    'meta-b': 'Gras',
    'meta-i': 'Italique',
    'meta-u': 'Souligné',
    'meta-h': 'Superscript',
    'meta-l': 'Subscript',
    'meta-k': 'Lien',
    'meta-alt-0': 'Texte normal',
    'meta-alt-1': 'Titre 1',
    'meta-alt-2': 'Titre 2',
    'meta-alt-3': 'Titre 3',
    'meta-alt-4': 'Titre 4',
    'meta-alt-5': 'Titre 5',
    'meta-alt-6': 'Titre 6',
    'meta-shift-7': 'Liste numérotée',
    'meta-shift-8': 'Liste à puces',
    'meta-indent': 'Indenter',
    'meta-outdent': 'Désindenter',
    'meta-shift-backspace': 'Supprimer',
    'meta-shift-o': 'Ajouter',
    'meta-shift-d': 'Dupliquer',
    'meta-shift-up': 'Monter',
    'meta-shift-down': 'Descendre',
  },
  buttons: {
    add: 'Ajouter',
    html: 'HTML',
    format: 'Format',
    bold: 'Gras',
    italic: 'Italique',
    deleted: 'Barré',
    link: 'Lien',
    list: 'Liste',
    image: 'Image',
    indent: 'Indenter',
    outdent: 'Désindenter',
    embed: 'Embed',
    table: 'Tableau',
    insert: 'Insérer',
    save: 'Enregistrer',
    cancel: 'Annuler',
    delete: 'Supprimer',
    duplicate: 'Dupliquer',
    shortcut: 'Raccourcis',
    underline: 'Souligné',
    undo: 'Annuler',
    redo: 'Refaire',
    code: 'Code',
    mark: 'Surligné',
    subscript: 'Subscript',
    superscript: 'Superscript',
    kbd: 'Shortcut',
    'image-settings': 'Paramètres Image',
    'column-settings': 'Paramètres Colonne',
  },
  blocks: {
    text: 'Texte',
    paragraph: 'Paragraphe',
    image: 'Image',
    embed: 'Embed',
    line: 'Ligne horizontale',
    table: 'Tableau',
    quote: 'Citation',
    pre: 'Code',
    address: 'Adresse',
  },
  format: {
    p: 'Texte',
    h1: 'Très gros titre',
    h2: 'Gros titre',
    h3: 'Titre',
    h4: 'Titre 4',
    h5: 'Titre 5',
    h6: 'Titre 6',
    address: 'Adresse',
    ul: 'Liste à puces',
    ol: 'Liste numérotée',
    dl: 'Liste à définition',
  },
  embed: {
    embed: 'Embed',
    caption: 'Caption',
    description: 'Paste any embed/html code or enter the url (vimeo or youtube video only)',
    'responsive-video': 'Responsive video',
  },
  image: {
    or: 'ou',
    'alt-text': 'Texte Alt',
    link: 'Lien',
    width: 'Largeur',
    caption: 'Sous-titre',
    'link-in-new-tab': 'Ouvrir le lien dans un nouvel onglet',
    'url-placeholder': "Coller l'url de l'image...",
    'upload-new-placeholder': "Déposer l'image ici<br>ou cliquer pour chercher une image",
  },
  link: {
    link: 'Lien',
    'edit-link': 'Modifier un lien',
    unlink: 'Supprimer le lien',
    'link-in-new-tab': 'Ouvrir le lien dans un nouvel onglet',
    text: 'Texte',
    url: 'URL',
  },
  table: {
    width: 'Largeur',
    nowrap: 'Pas de retour à la ligne',
    column: 'Colonne',
    'add-head': 'Ajouter une ligne de titre',
    'remove-head': 'Supprimer la ligne de titre',
    'add-row-below': 'Ajouter une ligne en dessous',
    'add-row-above': 'Ajouter une ligne au-dessus',
    'remove-row': 'Supprimer la ligne',
    'add-column-after': 'Ajouter une colonne à droite',
    'add-column-before': 'Ajouter une colonne à gauche',
    'remove-column': 'Supprimer la colonne',
    'delete-table': 'Supprimer le tableau',
  },
};
