import "core-js/modules/es.set";
import "core-js/modules/es.map";

import React from "react";
import ReactDOM from "react-dom";
import AdminRoadMap from "../../components/admin/roadmap";
import RoadMapService from "../../services/roadmap_service";

document.addEventListener("DOMContentLoaded", () => {
  const node = document.getElementById('adminRoadMap');

  if (node !== null) {
    const { urls, accountId } = node.dataset;
    const service = new RoadMapService(JSON.parse(urls), accountId);

    ReactDOM.render(<AdminRoadMap service={ service } />, node);
  }
});
