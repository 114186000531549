$(function() {
  $(document).on('change', '[data-toggle="select-collapse"] select', function(e) {
    var input = $(this);
    var val = input.val();
    var container = input.closest('[data-toggle="select-collapse"]');
    var showOption = container.data('show-option');
    var collapse = container.find('.select-collapse');
    if (val === showOption) {
      collapse.addClass('show');
      collapse.trigger('select-collapse:show');
    } else {
      collapse.removeClass('show');
      collapse.trigger('select-collapse:hide');
    }
  });

  $(document).on('select-collapse:show', function(e) {
    $(e.target).find('input, select').prop('disabled', false);
  });

  $(document).on('select-collapse:hide', function(e) {
    $(e.target).find('input, select').prop('disabled', true);
  });
});
