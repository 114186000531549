import React, { Component } from 'react';
import GoalsList from './goals_list';
import GoalsSelector from './goals_selector';
import { Button, Modal } from 'react-bootstrap';

const genericErrorMessage = "Erreur lors de l'action, veuillez rafraîchir la page.";

export default class AdminRoadMap extends Component {
  noActiveGoal = {};

  constructor(props) {
    super(props);

    this.state = {
      activeGoal: this.noActiveGoal,
      isDirty: false,
      goals: [],
      goalTemplates: [],
      loading: true,
      submitError: false,
      submitMessage: null,
    };
  }

  componentDidMount() {
    this.getRoadMap();
  }

  getRoadMap = () => {
    return this.props.service.getRoadMap().then((data) => {
      return this.setRoadMap(data);
    });
  };

  setRoadMap = ({ goals, goalTemplates }, submitMessage = null) => {
    return this.setState({
      goals,
      goalTemplates,
      submitMessage,
      loading: false,
    });
  };

  saveSelectedGoalTemplates = (goalTemplates) => {
    return this.props.service.saveGoalTemplates(goalTemplates).then(
      (data) => {
        this.setRoadMap(data, goalTemplates.length > 1 ? 'Priorités ajoutées !' : 'Priorité ajoutée');
      },
      () => {
        this.setState({ submitError: true, submitMessage: genericErrorMessage });
      }
    );
  };

  updateGoal = (goal) => {
    return this.props.service.updateGoal(goal).then(
      (newGoal) => {
        const isUpdatedGoal = (stateGoal) => stateGoal.id === parseInt(goal.id);
        const goalIndex = this.state.goals.findIndex(isUpdatedGoal);

        let newGoals = Object.assign([], this.state.goals);
        newGoals[goalIndex] = newGoal;

        const submitMessage = null; // no success popup
        this.setState({ activeGoal: newGoal, goals: newGoals, isDirty: false, submitMessage });

        return newGoal;
      },
      () => {
        this.setState({ submitError: true, submitMessage: genericErrorMessage });
      }
    );
  };

  destroyGoal = (goal) => {
    return this.props.service.destroyGoal(goal).then(
      (newGoal) => {
        const isDeletedGoal = (stateGoal) => stateGoal.id === parseInt(goal.id);
        const goalIndex = this.state.goals.findIndex(isDeletedGoal);

        let newGoals = Object.assign([], this.state.goals);
        delete newGoals[goalIndex];
        const activeGoal = newGoals[0] || this.noActiveGoal;

        this.setState({ activeGoal: activeGoal, goals: newGoals, isDirty: false });

        return;
      },
      () => {
        this.setState({ submitError: true, submitMessage: genericErrorMessage });
      }
    );
  };

  setActiveGoal = (goal) => {
    this.setState({ activeGoal: goal || this.noActiveGoal, isDirty: false });
  };

  setIsDirty = (dirty) => {
    this.setState({ isDirty: dirty });
  };

  onDirtyGoal = (dirty) => {
    this.setIsDirty(dirty);
  };

  clearMessage = () => {
    this.setState({ submitError: false, submitMessage: null });
  };

  submitMessage = () => {
    return (
      <div className='static-modal'>
        <Modal show={!!this.state.submitMessage} variant='small'>
          <Modal.Body>{(this.state.submitError ? '⚠️ ' : '✅ ') + this.state.submitMessage}</Modal.Body>

          <Modal.Footer>
            <Button onClick={this.clearMessage}>Fermer</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  render() {
    if (this.state.loading) {
      return <div className='alert alert-info'>Chargement…</div>;
    }

    return (
      <>
        <GoalsSelector
          goalTemplates={this.state.goalTemplates}
          goals={this.state.goals}
          saveSelectedGoalTemplatesFn={this.saveSelectedGoalTemplates}
        />

        {this.submitMessage()}

        <GoalsList
          activeGoal={this.state.activeGoal}
          isDirty={this.state.isDirty}
          goals={this.state.goals}
          userRoadMapURL={this.props.service.userRoadMapURL()}
          onGoalUpdateFn={this.updateGoal}
          onGoalDestroyFn={this.destroyGoal}
          onGoalTabClickFn={this.setActiveGoal}
          onDirtyGoalFn={this.onDirtyGoal}
        />
      </>
    );
  }
}
