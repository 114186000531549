$(function() {
  $(document).on('change', 'input[data-toggle="checkbox-collapse"]', function(e) {
    var checkbox = $(this);
    var target = $(checkbox.data('href'));
    var inverted = checkbox.data('toggle-inverted');

    var checked = checkbox.is(':checked');

    var extraCondition = checkbox.data('toggle-condition');

    if(extraCondition != undefined) {
      checked = extraCondition
    } else if (inverted) {
      checked = !checked;
    }

    if (checked) {
      target.addClass('show');
    } else {
      target.removeClass('show');
    }
  });
});
