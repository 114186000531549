import React, { useLayoutEffect } from 'react';
import { Form, InputGroup, Button } from 'react-bootstrap';
import { FormContext, useForm, useFormContext } from 'react-hook-form';
import { translateValidationMessage } from './validations';
import { get } from 'lodash';

export function NcForm({ defaultValues, children, onSubmit, onAutoSave, validationSchema, ...rest }) {
  const mode = onAutoSave ? 'onBlur' : 'onSubmit';
  const methods = useForm({ defaultValues, mode, validationSchema });
  const { handleSubmit, formState } = methods;

  return (
    <FormContext {...methods} validationSchema={validationSchema}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        onBlur={onAutoSave && formState.dirty && formState.isValid ? handleSubmit(onAutoSave) : null}
        {...rest}
      >
        {children}
      </Form>
    </FormContext>
  );
}

export function NcInput({ name, label, helpText, componentClass, htmlFor = null, ...rest }) {
  const helpIcon = <i className='fas fa-question-circle' />;
  let classNames = [];

  if (componentClass === 'textarea') {
    classNames += 'autosize';
  }

  useLayoutEffect(() => {
    hookAutosize();
  }, []);

  return (
    <NcFormGroup inputName={name}>
      <NcLabel label={label} htmlFor={htmlFor || name} withHelp={helpText} />
      <NcFormControl
        name={name}
        componentClass={componentClass}
        className={classNames}
        htmlFor={htmlFor}
        {...rest}
      />
      {helpText && (
        <Form.Text>
          {helpIcon} {helpText}
        </Form.Text>
      )}
    </NcFormGroup>
  );
}

function NcFormControl({ name, validationOptions, componentClass = 'input', htmlFor, ...rest }) {
  const { register } = useFormContext();
  const inputRef = register(validationOptions || {});

  return (
    <Form.Control as={componentClass} id={htmlFor || name} name={name} ref={inputRef} {...rest} />
  );
}

export function NcFormInputWithAddon({ name, label, helpText, children, before = true, ...rest }) {
  const addon = <InputGroup.Text>{children}</InputGroup.Text>;

  return (
    <NcFormGroup inputName={name}>
      <NcLabel label={label} htmlFor={name} withHelp={helpText} />
      <InputGroup>
        {before && addon}
        <NcFormControl name={name} {...rest} />
        {!before && addon}
      </InputGroup>
    </NcFormGroup>
  );
}

export function NcFormInputWithButton({ name, label, helpText, children, before = true, ...rest }) {
  const addon = <Button>{children}</Button>;

  return (
    <NcFormGroup inputName={name}>
      <NcLabel label={label} htmlFor={name} withHelp={helpText} />
      <InputGroup>
        {before && addon}
        <NcFormControl name={name} {...rest} />
        {!before && addon}
      </InputGroup>
    </NcFormGroup>
  );
}

/**
 * @return {null}
 */
export function NcLabel({ label, htmlFor, withHelp = false }) {
  let controlLabel = null;

  if (label && label.length > 0) {
    controlLabel = (
      <Form.Label htmlFor={htmlFor}>
        {label}
        {withHelp && (
          <>
            {' '}
            <i className='fas fa-question-circle' />
          </>
        )}
      </Form.Label>
    );
  }

  return controlLabel;
}

export function NcSelect({ name, label, validationOptions, options, helpText, ...rest }) {
  const { register } = useFormContext();
  const inputRef = register(validationOptions || {});

  return (
    <NcFormGroup inputName={name}>
      <NcLabel label={label} htmlFor={name} withHelp={helpText} />
      <Form.Select className='form-control' name={name} id={name} ref={inputRef} {...rest}>
        {options.map((option) => (
          <option value={option[0]} key={option[0]}>
            {option[1]}
          </option>
        ))}
      </Form.Select>
      {helpText && (
        <Form.Text>
          <i className='fas fa-question-circle' /> {helpText}
        </Form.Text>
      )}
    </NcFormGroup>
  );
}

export function NcCheckList({ name, label, validationOptions, options, helpText, ...rest }) {
  const { register } = useFormContext();
  const inputRef = register(validationOptions || {});

  return (
    <NcFormGroup inputName={name}>
      <NcLabel label={label} htmlFor={name} withHelp={helpText} />
      {options.map((option) => (
        <Form.Check type='checkbox' name={name} value={option[0]} key={option[0]} ref={inputRef} {...rest}>
          {option[1]}
        </Form.Check>
      ))}
      {helpText && (
        <Form.Text>
          <i className='fas fa-question-circle' /> {helpText}
        </Form.Text>
      )}
    </NcFormGroup>
  );
}

export function NcRadioList({ name, label, validationOptions, options, helpText, ...rest }) {
  const { register } = useFormContext();
  const inputRef = register(validationOptions || {});

  return (
    <NcFormGroup inputName={name}>
      <NcLabel label={label} htmlFor={name} withHelp={helpText} />
      <br />
      {options.map((option) => (
        <Form.Check inline type='radio' name={name} value={option[0]} key={option[0]} label={option[1]} ref={inputRef} {...rest}>
        </Form.Check>
      ))}
      {helpText && (
        <Form.Text>
          <i className='fas fa-question-circle' /> {helpText}
        </Form.Text>
      )}
    </NcFormGroup>
  );
}

export function NcYesNoRadio({ ...rest }) {
  const trueFalseOptions = [['true', 'Oui'], ['false', 'Non']];

  return <NcRadioList options={trueFalseOptions} {...rest} />;
}

export function NcYesNoRadioWithCondition({ name, thenComponent, elseComponent, ...rest }) {
  const { watch } = useFormContext();
  const watchCondition = watch(name);

  return (
    <>
      <NcYesNoRadio name={name} {...rest} />
      {watchCondition && (watchCondition === 'true' ? thenComponent : elseComponent)}
    </>
  );
}

export function NcButton({ text, type = 'submit', variant = 'default', ...rest }) {
  const { errors } = useFormContext();

  const disableButtonOnInvalidForm = type === 'submit' && Object.keys(errors).length > 0;
  return (
    <Button variant={variant} type={type} {...rest} disabled={disableButtonOnInvalidForm}>
      {text}
    </Button>
  );
}

function errorForInput(inputName, errors) {
  const inputNameRegex = /([^\[\]]|\[\])+/g;

  const nameParts = inputName.match(inputNameRegex);
  if (nameParts && nameParts.length > 1) {
    return get(errors, nameParts.join('.'));
  }

  return errors[inputName];
}

function NcFormGroup({ children, inputName }) {
  let feedBack;

  const { validationSchema, errors } = useFormContext();
  const inputError = errorForInput(inputName, errors);
  const withError = inputError !== undefined;

  if (withError) {
    const errorMessage = validationSchema ? inputError['message'] : translateValidationMessage(inputError['type']);
    feedBack = <Form.Text>{errorMessage}</Form.Text>;
  }

  return (
    <Form.Group className="form-group mb-3" validationState={withError ? 'error' : null}>
      {children}

      {feedBack}
    </Form.Group>
  );
}
