import RestClient from '../lib/rest_client';
import { find, reject, sortBy } from 'lodash';

export default class RoadMapService {
  constructor(urls, accountID) {
    this.urls = urls;
    this.accountID = accountID;
    this.goalTemplates = [];
  }

  userRoadMapURL = () => {
    return this.urls.userRoadMapURL;
  };

  // API methods

  getRoadMap = () => {
    return this.enrichRoadmapPromise(RestClient.get(this.urls.showURL));
  };

  createGoals = (goalTemplates) => {
    return this.enrichRoadmapPromise(
      RestClient.post(this.urls.createGoalsURL, { goal_templates_params: goalTemplates })
    );
  };

  updateGoal = (goal) => {
    let goalParams = this.buildParamsForGoal(goal);

    return new Promise((resolve, reject) => {
      RestClient.patch(`${this.urls.updateGoalURL}.${goal.id}`, { goal_params: goalParams }).then(
        (response) => {
          return resolve(this.enrichGoal(response.data.goal, this.getGoalTemplates()));
        },
        (error) => {
          return reject(error);
        }
      );
    });
  };

  destroyGoal = (goal) => {
    return new Promise((resolve, reject) => {
      RestClient.delete(`${this.urls.destroyGoalURL}`, {
        data: { account_id: this.accountID, goal_id: goal.id },
      }).then(
        (response) => {
          return resolve(this.enrichGoal(response.data.goal, this.getGoalTemplates()));
        },
        (error) => {
          console.log(error);
          return reject(error);
        }
      );
    });
  };

  saveGoalTemplates = (goalTemplates) => {
    let fakeGoals = this.buildFakeGoals(goalTemplates);

    return this.createGoals(fakeGoals);
  };

  // Data Helpers

  enrichRoadmapPromise = (promise) => {
    return new Promise((resolve, reject) => {
      promise.then(
        (response) => {
          const goalTemplates = response.data.roadmap.goal_templates;
          const activeGoalTemplates = this.activeGoalTemplates(response.data.roadmap.goal_templates);

          const goals = this.enrichGoals(response.data.roadmap.goals, goalTemplates);

          this.setGoalTemplates(goalTemplates);

          return resolve({ goals, goalTemplates: activeGoalTemplates });
        },
        (error) => {
          return reject(error);
        }
      );
    });
  };

  setGoalTemplates = (goalTemplates) => {
    this.goalTemplates = goalTemplates;

    return this;
  };

  getGoalTemplates = () => {
    return this.goalTemplates;
  };

  // Params methods

  buildFakeGoals = (goalTemplates) => {
    return goalTemplates.map(this.buildFakeGoal);
  };

  buildFakeGoal = (goalTemplate) => {
    return {
      goal_template_id: goalTemplate.id,
      title: goalTemplate.title,
      indicator: goalTemplate.indicator,
      priority: goalTemplate.priority,
      visible: goalTemplate.visible,
      account_id: this.accountID,
    };
  };

  isUnselectedTemplate = (params) => {
    return params.id === '' && params.checked === false;
  };

  buildParamsForGoal = (goal) => {
    let goalParams = Object.assign({}, goal);

    // @todo Use goalParams.steps.filter instead of reject
    let stepsParams = reject(goalParams.steps, this.isUnselectedTemplate).map(this.formatStepParams);

    delete goalParams.steps;

    if (stepsParams.length === 0) {
      return goalParams;
    }

    goalParams.steps_attributes = stepsParams;

    return goalParams;
  };

  formatStepParams = (stepParams) => {
    let newStepParams = Object.assign({}, stepParams);

    newStepParams._destroy = !newStepParams.checked;

    // @todo Use stepParams.actions.filter instead of reject
    let actionsParams = reject(stepParams.actions, this.isUnselectedTemplate).map(this.formatActionParams);

    delete newStepParams.actions;
    delete newStepParams.checked;

    if (actionsParams.length === 0) {
      return newStepParams;
    }

    newStepParams.actions_attributes = actionsParams;

    return newStepParams;
  };

  formatActionParams = (actionParams) => {
    let newActionParams = Object.assign({}, actionParams);

    newActionParams._destroy = !newActionParams.checked;

    delete newActionParams.checked;

    return newActionParams;
  };

  activeGoalTemplates = (goalTemplates) => {
    return goalTemplates.filter((gt) => !gt.deleted);
  };

  // Data structures

  enrichGoals = (goals, goalTemplates) => {
    return goals.map((goal) => this.enrichGoal(goal, goalTemplates));
  };

  enrichGoal = (goal, goalTemplates) => {
    const newGoal = Object.assign({}, goal);
    const goalTemplate = this.goalTemplateForGoal(goal, goalTemplates);
    const stepTemplates = goalTemplate.action_plan_step_templates;

    newGoal.steps = this.enrichStepsForGoal(newGoal, stepTemplates);
    newGoal.template_deleted = goalTemplate.deleted;

    return newGoal;
  };

  enrichStepsForGoal = (goal, stepTemplates) => {
    const steps = Object.assign([], goal.steps);

    const mergedSteps = [
      ...this.stepsForGoal(steps, stepTemplates),
      ...this.availableStepTemplates(steps, stepTemplates).map(this.convertStepTemplateToStep),
    ];

    return sortBy(mergedSteps, ['step_template_id']).map((step) => {
      step.account_id = goal.account_id;

      return step;
    });
  };

  goalTemplateForGoal = (goal, goalTemplates) => {
    return find(goalTemplates, { id: parseInt(goal.goal_template_id) });
  };

  stepsForGoal = (steps, stepTemplates) => {
    return steps.map((step) => {
      const stepTemplate = this.stepTemplateForStep(step, stepTemplates);

      if (!stepTemplate) {
        return step;
      } // custom step

      const actionTemplates = stepTemplate.action_templates;

      const actions = step.actions;

      const mergedActions = [
        ...this.actionsForStep(step, actions),
        ...this.availableActionTemplates(actions, actionTemplates).map((actionTemplate) =>
          this.convertActionTemplateToAction(actionTemplate, step.id)
        ),
      ];

      step.checked = true;
      step.actions = sortBy(mergedActions, ['action_template_id']);

      return step;
    });
  };

  availableStepTemplates = (steps, stepTemplates) => {
    const selectedStepTemplatesIds = steps.map((step) => {
      return step.step_template_id;
    });
    const activeAndSelected = (template) => {
      return !template.deleted && !selectedStepTemplatesIds.includes(template.id);
    };

    return stepTemplates.filter(activeAndSelected);
  };

  convertStepTemplateToStep = (template) => {
    let newTemplate = Object.assign({}, template);
    const stepID = null;

    newTemplate.id = stepID;
    newTemplate.step_template_id = template.id;
    newTemplate.actions = template.action_templates?.map((actionTemplate) =>
      this.convertActionTemplateToAction(actionTemplate, stepID)
    );

    delete newTemplate.action_templates;

    return newTemplate;
  };

  actionsForStep = (step, actions) => {
    return actions.map((action) => {
      action.checked = true;
      action.step_id = step.id;

      return action;
    });
  };

  availableActionTemplates = (actions, actionTemplates) => {
    const selectedActionTemplatesIds = actions.map((action) => {
      return action.action_template_id;
    });

    return actionTemplates.filter((template) => !selectedActionTemplatesIds.includes(template.id));
  };

  convertActionTemplateToAction = (template, stepID = null) => {
    let newTemplate = Object.assign({}, template);

    newTemplate.action_template_id = template.id;
    newTemplate.step_id = stepID;
    newTemplate.id = null;
    newTemplate.checked = !stepID;

    return newTemplate;
  };

  stepTemplateForStep = (step, stepTemplates) => {
    return find(stepTemplates, { id: parseInt(step.step_template_id) });
  };
}
