$(function() {
  $(document).on('change', '.project-type input', function() {
    var radio = $(this);
    val = radio.val();
    var domains = radio.closest('.website-brief').find('.pages-to-transfer');
    if (val == 'redesign') {
      domains.removeClass('hidden');
    } else {
      domains.addClass('hidden');
    }
  });
});
