import Select from "react-select";
import { Button } from "react-bootstrap";
import React, { useState } from "react";
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

export default function GoalsSelector({ goalTemplates, goals, saveSelectedGoalTemplatesFn }) {
  const [selectedGoalTemplatesOptions, setSelectedGoalTemplatesOptions] = useState([]);

  const getGoalTemplatesOptions = () => {
    const templates = availableGoalTemplates();

    return attributeAsOptions(templates);
  };

  const availableGoalTemplates = () => {
    const selectedGoalTemplatesIds = goals.map((goal) => { return goal.goal_template_id });

    return goalTemplates.filter((template) => { return !selectedGoalTemplatesIds.includes(template.id) })
  };

  const attributeAsOptions = (items) => {
    return items.map((item) => {
      let option = Object.assign({}, item);

      option['value'] = item.id;
      option['label'] = item.title;

      return option;
    });
  };

  const onSelectChange = (values) => {
    setSelectedGoalTemplatesOptions(values || []);
  };

  const onSubmit = () => {
    saveSelectedGoalTemplatesFn(selectedGoalTemplatesOptions).then(() => {
      reset()
    });
  };

  const reset = () => {
    setSelectedGoalTemplatesOptions([]);
  };

  return <div className="jumbotron clearfix h-100 p-5 bg-light border rounded-3 mb-3">
    <Select
      isMulti
      value={ selectedGoalTemplatesOptions }
      options={ getGoalTemplatesOptions() }
      closeMenuOnSelect={ false }
      components={ animatedComponents }
      onChange={ onSelectChange }
      placeholder="Choisissez des priorités"
      noOptionsMessage={ () => "Plus de priorités disponibles" }
      className="goal-templates--selector mb-3"
    />

    <div className="pull-right">
      <Button variant="primary"
              onClick={ onSubmit }
              disabled={ selectedGoalTemplatesOptions.length === 0 }>
        Ajouter à la feuille de route
      </Button>
    </div>
  </div>
}
