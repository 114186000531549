import { NcButton, NcInput } from '../../../../shared_components/neocamino/inputs';
import React, { useEffect, useRef } from 'react';
import StepsList from './steps_list';
import { FormContext, useForm } from 'react-hook-form';
import { Alert, Form } from 'react-bootstrap';
import { filter } from 'lodash';

export default function GoalPanel({
  goal,
  isDirty,
  modifiedPriorityText,
  deletedPriorityText,
  onPanelSubmitFn,
  onPanelCloseFn,
  onPanelDeleteFn,
  onDirtyGoalFn,
}) {
  const isInitialMount = useRef(true);

  const methods = useForm({ defaultValues: goal, mode: 'onBlur' });
  const { handleSubmit, setValue, formState, watch } = methods;

  const watchSteps = watch('steps');

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      updateNestedFields(goal);
    }
  }, [goal]);

  const onFormSubmit = (goal) => {
    return onPanelSubmitFn(goal);
  };

  const onFormChange = () => {
    if (isDirty) {
      return;
    }

    onDirtyGoalFn(true);
  };

  const updateNestedFields = (goal) => {
    goal.steps.map((step, stepIndex) => {
      const stepBase = `steps[${stepIndex}]`;

      setValue(`${stepBase}[id]`, step.id);

      step.actions.map((action, actionIndex) => {
        const actionBase = `${stepBase}[actions][${actionIndex}]`;

        setValue(`${actionBase}[id]`, action.id);
        setValue(`${actionBase}[step_id]`, step.id);
      });
    });
  };

  const onCancelClick = () => {
    if (isDirty && !confirm(modifiedPriorityText)) {
      return false;
    }

    onPanelCloseFn();
  };

  const onDeletedClick = () => {
    if (confirm(deletedPriorityText)) {
      onPanelDeleteFn(goal);
    }
  };

  const publishedSteps = () => {
    return filter(watchSteps, (step) => parseInt(step.id) > 0);
  };

  const templateStateIndicator = () => {
    return goal.template_deleted && <Alert variant='warning'>Cette priorité a été supprimée de l'admin.</Alert>;
  };

  const stateIndicator = () => {
    return (
      <p>
        <strong>État actuel : </strong>
        {isDirty ? 'Modifiée et ' : ''}
        {publishedSteps().length > 0 ? 'Visible' : 'Masquée'} sur la feuille de route.
      </p>
    );
  };

  return (
    <div className={formState.isSubmitting ? 'goal-panel--is-saving' : ''}>
      <h4>{goal.title + (isDirty ? ' *' : '')}</h4>

      <FormContext {...methods}>
        <Form onSubmit={handleSubmit(onFormSubmit)} onChange={onFormChange}>
          <NcInput name='id' type='hidden' />

          {templateStateIndicator()}

          <NcInput name='title' label='Titre' validationOptions={{ required: true }} />

          <NcInput name='indicator' label='Indicateur' />

          {stateIndicator()}

          <hr />

          <StepsList />

          <div className='pull-left'>
            <NcButton type='button' variant='danger' text='Supprimer' onClick={onDeletedClick} />
          </div>
          <div className='pull-right'>
            <NcButton type='button' text='Annuler' onClick={onCancelClick} />
            &nbsp;
            <NcButton
              text={formState.isSubmitting ? 'Sauvegarde en cours…' : 'Valider'}
              variant='success'
              disabled={formState.isSubmitting}
            />
          </div>

          <div className='clearfix' />
        </Form>
      </FormContext>
    </div>
  );
}
