import React from 'react';
import StepItem from './step_item';
import { Alert } from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';

export default function StepsList() {
  const { control } = useFormContext();
  const { fields } = useFieldArray({ control, name: 'steps', keyName: 'cID' });

  const noStepsMessages = () => {
    if (fields.length > 0) {
      return null;
    }

    return <Alert variant='info'>Il n'y a pas d'étapes prédéfinies.</Alert>;
  };

  return (
    <>
      <h4>Étapes</h4>

      {fields.map((step, index) => {
        return (
          <StepItem
            stepBase={`steps[${index}]`}
            key={step.cID}
            stepID={step.id}
            stepTitle={step.title}
            stepRoutine={step.routine}
            actions={step.actions}
          />
        );
      })}

      {noStepsMessages()}
    </>
  );
}
