import { setLocale } from "yup";

const defaultValidationMessages = {
  default: 'Erreur sur le champ, veuillez réessayer.',
  required: 'Ce champ ne peut être vide',
};

export function translateValidationMessage(key) {
  const availableMessages = {
    required: defaultValidationMessages.required,
    minLength: 'Ce champ est trop court',
    maxLength: 'Ce champ est trop long',
  };

  return availableMessages[key] || defaultValidationMessages.default;
}

export function setYupLocale() {
  return setLocale({
    mixed: {
      default: defaultValidationMessages.default,
      required: defaultValidationMessages.required,
      notType: (_ref) => {
        switch (_ref.type) {
          case 'number':
            return 'Ce champ doit contenir une valeur numérique';
          case 'string':
            return 'Ce champ doit contenir un texte';
          default:
            return 'La valeur de ce champ est invalide';
        }
      },
    },
    number: {
      min: 'Ce champ doit être supérieur ou égal à ${min}',
      max: 'Ce champ doit être inférieur ou égal à ${max}',
      integer: 'Ce champ doit être un nombre entier',
    },
    string: {
      max: 'Ce champ a un maximum de ${max} caractères',
      url: 'Ce champ doit contenir une URL valide',
    },
  });
}
