import React, { useState } from 'react';
import ActionItem from './action_item';
import { Alert, Form, Collapse } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { NcInput } from '../../../../shared_components/neocamino/inputs';

export default function StepItem({ stepBase, stepID, stepTitle, stepRoutine }) {
  const { register, watch, control, clearError } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: `${stepBase}[actions]`,
    keyName: 'cID',
  });

  const watchChecked = watch(`${stepBase}[checked]`);
  const isChecked = !watchChecked;

  const watchTitle = watch(`${stepBase}[title]`, stepTitle);

  const [isOpen, setOpen] = useState(watchChecked);

  const routineLabel = () => {
    if (!stepRoutine) {
      return null;
    }

    return <span className='badge text-bg-info'>Routine</span>;
  };

  const onStepCheck = () => {
    setOpen(isChecked);

    if (!isChecked) {
      clearError(stepBase);
    }
  };

  const newRoutine = () => {
    return stepID == undefined;
  };

  const routineActionMessage = () => {
    if (!stepRoutine) {
      return null;
    }

    return (
      <div>
        <p>Une action par mois.</p>
        {routineModificationWarning()}
      </div>
    );
  };
  const routineModificationWarning = () => {
    if (newRoutine()) {
      return null;
    }

    return (
      <div className='alert alert-info'>
        <p>Les modifications de cette routine n'affecteront pas les actions déjà réalisées.</p>
        <p>
          Si vous modifiez la date de cette routine, toutes les actions non-réalisées seront supprimées/recréées.
        </p>
      </div>
    );
  };

  const noActionsMessages = () => {
    if (stepRoutine || fields.length > 0) {
      return null;
    }

    return <Alert variant='info'>Il n'y a pas d'actions prédéfinies.</Alert>;
  };

  return (
    <div>
      <input type='hidden' name={`${stepBase}[id]`} ref={register} />
      <input type='hidden' name={`${stepBase}[account_id]`} ref={register} />
      <input type='hidden' name={`${stepBase}[step_template_id]`} ref={register} />

      <Card className='nc-step--container'>
        <div className='nc-step--infos'>
          <div className='nc-step--infos-container'>
            <div className='nc-step--title-line'>
              <div className='pull-left'>
                <Form.Check type='checkbox' name={`${stepBase}[checked]`} ref={register} onChange={onStepCheck} label={watchTitle}/>
              </div>
            </div>
            {routineLabel()}
          </div>
        </div>
        <div>
          <Collapse in={isOpen}>
            <Card.Body>
              <NcInput
                name={`${stepBase}[title]`}
                label='Titre'
                validationOptions={{
                  defaultValue: stepTitle,
                  required: watchChecked,
                }}
              />
              <NcInput
                name={`${stepBase}[due_on]`}
                label={stepRoutine ? 'Chaque mois à partir du' : 'À réaliser pour le'}
                type='date'
                validationOptions={{ required: watchChecked }}
              />
              <hr />

              <strong>Actions</strong>

              {fields.map((action, index) => {
                return (
                  <ActionItem
                    actionBase={`${stepBase}[actions][${index}]`}
                    key={action.cID}
                    title={action.title}
                    stepID={stepID}
                  />
                );
              })}

              {routineActionMessage()}
              {noActionsMessages()}
            </Card.Body>
          </Collapse>
        </div>
      </Card>
    </div>
  );
}
