RedactorX.add('plugin', 'extended-context', {
  start: function() {
    this.app.context.add('context.ul', {
      title: '## format.ul ##',
      icon: '<i class="fas fa-list-ul"></i>',
      command: 'block.format',
      params: { tag: 'ul' },
    });
    this.app.context.add('context.ol', {
      title: '## format.ol ##',
      icon: '<i class="fas fa-list-ol"></i>',
      command: 'block.format',
      params: { tag: 'ol' },
    });
  },
});
