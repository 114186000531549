import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button, Col, Row, Form } from 'react-bootstrap';
import { NcInput } from '../../../../shared_components/neocamino/inputs';

export default function ActionItem({ title, actionBase }) {
  const { register, setValue, watch } = useFormContext();
  const [showField, setShowField] = useState(false);

  const watchTitle = watch(`${actionBase}[title]`, title);

  const toggleShowField = () =>
    setShowField((showField) => {
      const newShowField = !showField;

      if (newShowField) {
        setValue(`${actionBase}[checked]`, newShowField);
      }

      return newShowField;
    });

  return (
    <>
      <input type='hidden' name={`${actionBase}[id]`} ref={register} />
      <input type='hidden' name={`${actionBase}[step_id]`} ref={register} />
      <input type='hidden' name={`${actionBase}[action_template_id]`} ref={register} />

      <Row>
        <Col sm={10}>
          <NcInput
            type={showField ? 'text' : 'hidden'}
            name={`${actionBase}[title]`}
            width='100%'
            validationOptions={{ required: true }}
          />
          <Form.Check
            type='checkbox'
            name={`${actionBase}[checked]`}
            className='goals-list--action-checkbox'
            ref={register}
            disabled={showField}
            label={showField ? null : watchTitle}
          />
        </Col>

        <Col sm={2}>
          <Button variant='secondary' onClick={toggleShowField} size='sm' className='goals-list--action-button'>
            <i className={showField ? 'fas fa-times' : 'fas fa-pencil-alt'} />
          </Button>
        </Col>
      </Row>
    </>
  );
}
