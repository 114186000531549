import { Alert, Col, Form, Row } from 'react-bootstrap';
import React from 'react';
import GoalPanel from './goal_panel';

export default function GoalsList({
  activeGoal,
  isDirty,
  goals,
  userRoadMapURL,
  onGoalUpdateFn,
  onGoalDestroyFn,
  onGoalTabClickFn,
  onDirtyGoalFn,
}) {
  const modifiedPriorityText =
    'La priorité en cours a été modifiée, vos changements seront perdus.' + ' Voulez-vous continuer ?';

  const deletedPriorityText =
    'La priorité en cours sera supprimée ainsi que toutes les étapes et actions associées.' +
    'Cette action est irréversible.' +
    'Êtes-vous sûr de vouloir continuer ?';

  const goalTabs = () => {
    return goals.map((goal) => goalTab(goal));
  };

  const goalTab = (goal) => {
    const isActiveGoal = activeGoal.id === goal.id;
    const classNames = `list-group-item${isActiveGoal ? ' active' : ''}`;

    return (
      <a key={goal.id} className={classNames} onClick={() => !isActiveGoal && onGoalTabClick(goal)}>
        <i className={goal.icon_class} />
        &nbsp;{goal.title + (isActiveGoal && isDirty ? ' *' : '')}
      </a>
    );
  };

  const onGoalTabClick = (goal) => {
    if (isDirty && !confirm(modifiedPriorityText)) {
      return false;
    }

    onGoalTabClickFn(goal);
  };

  const goalPanel = () => {
    if (Object.keys(activeGoal).length === 0) {
      return null;
    }

    return (
      <GoalPanel
        key={activeGoal.id}
        goal={activeGoal}
        isDirty={isDirty}
        modifiedPriorityText={modifiedPriorityText}
        deletedPriorityText={deletedPriorityText}
        onPanelSubmitFn={onGoalUpdateFn}
        onPanelCloseFn={() => onGoalTabClickFn(null)}
        onPanelDeleteFn={onGoalDestroyFn}
        onDirtyGoalFn={onDirtyGoalFn}
      />
    );
  };

  const noGoalSelectedMessage = (
    <>
      <Form.Text>Veuillez cliquer sur une priorité pour l'éditer.</Form.Text>

      <a href={userRoadMapURL} target='_blank'>
        Voir la feuille de route
      </a>
    </>
  );

  if (goals.length === 0) {
    return <Alert variant='info'>Veuillez ajouter des priorités pour pouvoir éditer les étapes et actions.</Alert>;
  }

  return (
    <div className='goals-list'>
      <Row className='goals-list--container'>
        <Col sm={5} className='goals-list--tabs'>
          <div className='list-group'>{goalTabs()}</div>
        </Col>
        <Col sm={7} className='goals-list--tab-content'>
          {goalPanel() || noGoalSelectedMessage}
        </Col>
      </Row>
    </div>
  );
}
